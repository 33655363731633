import { Box, Typography, useTheme, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import HourlyStatusBarChart from "../../components/HourlyStatusBarChart";
import TerminalOverviewPie from "../../components/TerminalOverviewPie";
import ScheduledArrDepBarChart from "../../components/ScheduledArrDepBarChart";
import PrioritisedFlightsBarChart from "../../components/PrioritisedFlightsBarChart";
import StatusOverviewPie from "../../components/StatusOverviewPie";
import { useState } from "react";
const DataDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [numOfHours, setNumOfHours] = useState(6);
  const handleNumOfHoursChange = (event) => {
    setNumOfHours(event.target.value);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DATA DASHBOARD" subtitle="Current Data Insights" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h4" fontWeight="600">
                Original Schedule
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="0 20px 0 20px">
            <ScheduledArrDepBarChart />
          </Box>
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h4"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Yet to Depart Flights by Scheduled Hour
          </Typography>
          <Box height="250px" mt="-20px">
            <HourlyStatusBarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Grounded Passengers by Terminal
          </Typography>
          <Box height="200px">
            <TerminalOverviewPie isDashboard={true} />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="20px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Departure Status for Next{" "}
            <Select
              value={numOfHours}
              onChange={handleNumOfHoursChange}
              label="Number of Hours"
            >
              {[...Array(24)].map((_, index) => (
                <MenuItem key={index} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            {" Hrs"}
          </Typography>

          <Box height="200px">
            <StatusOverviewPie isDashboard={true} numOfHours={numOfHours} />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h4"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Prioritised Flights
          </Typography>
          <Box height="250px" mt="-20px">
            <PrioritisedFlightsBarChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DataDashboard;
