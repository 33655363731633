import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import WindowSelectionDialog from "./SelectionDialog";
import React, { useState } from "react";
import instance from "./GlobalAxios";

const SuggestedWindowTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFlightId, setSelectedFlightId] = useState("");

  const handleRejectButtonClick = () => {
    selectedRows.forEach((selectedRow) => {
      const requestData = { id: selectedRow };
      // Send a POST request to your backend
      instance
        .post("flights/rejectFlight", requestData)
        .then((response) => {
          console.log("POST request successful", response);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error sending POST request", error);
        });
    });
  };

  const handleMoveButtonClick = (flightId) => {
    setIsDialogOpen(true);
    setSelectedFlightId(flightId);
  };

  const handleWindowSelect = (newWindow) => {
    const requestData = { id: selectedFlightId, newWindow: newWindow };
    instance
      .post("flights/moveFlight", requestData)
      .then((response) => {
        console.log("POST request successful", response);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error sending POST request", error);
      });
  };

  // Define custom CSS classes for different countdown ranges
  // TODO: CHANGE TO ONE LEVEL
  const getCountdownCellClassName = (params) => {
    const countdown = params.value || 0;
    if (countdown <= 2) {
      return "dead-countdown-cell";
    } else {
      return "high-countdown-cell";
    }
  };

  const columns = [
    { field: "flightno", headerName: "Flight NO.", width: 100 },
    { field: "destination", headerName: "Origin", width: 80 },
    { field: "terminal", headerName: "Terminal", width: 80 },
    {
      field: "scheduled_datetime",
      headerName: "Scheduled DateTime",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        if (params.row.scheduled_datetime === "") {
          return "";
        }
        const scheduledDatetime = new Date(params.row.scheduled_datetime);
        const year = scheduledDatetime.getUTCFullYear();
        const month = scheduledDatetime.getUTCMonth() + 1; // Month is 0-based, so add 1
        const day = scheduledDatetime.getUTCDate();
        const hours = scheduledDatetime.getUTCHours();
        const minutes = scheduledDatetime.getUTCMinutes();
        const seconds = scheduledDatetime.getUTCSeconds();

        const formattedDateTime = `${year}-${month
          .toString()
          .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return new Date(formattedDateTime);
      },
      valueFormatter: (params) => {
        console.log(params.value);
        if (params.value == null) {
          return "";
        }
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };

        return params.value.toLocaleString(undefined, options);
      },
    },
    {
      field: "suggested_slot",
      headerName: "Suggested Slot",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        if (params.row.suggested_slot === "") {
          return "";
        }
        const suggestedSlotDT = new Date(params.row.suggested_slot);
        const year = suggestedSlotDT.getUTCFullYear();
        const month = suggestedSlotDT.getUTCMonth() + 1; // Month is 0-based, so add 1
        const day = suggestedSlotDT.getUTCDate();
        const hours = suggestedSlotDT.getUTCHours();
        const minutes = suggestedSlotDT.getUTCMinutes();
        const seconds = suggestedSlotDT.getUTCSeconds();

        const formattedDateTime = `${year}-${month
          .toString()
          .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return new Date(formattedDateTime);
      },
      valueFormatter: (params) => {
        console.log(params.value);
        if (params.value == null) {
          return "";
        }
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };

        return params.value.toLocaleString(undefined, options);
      },
    },
    { field: "manual_window", headerName: "Manual Window", width: 140 },
    { field: "flight_type", headerName: "Flight Type", width: 100 },
    { field: "total_pax", headerName: "Total Pax", width: 100 },
    { field: "trf_pax", headerName: "Trf Pax", width: 120 },
    { field: "od_pax", headerName: "OD Pax", width: 120 },
    { field: "frequency", headerName: "Frequency", width: 100 },
    { field: "duration", headerName: "Duration", width: 100 },
    { field: "priority", headerName: "Priority Score", width: 100 },
    // { field: "runway_id", headerName: "Runway ID", width: 100 },
    { field: "flight_status", headerName: "Status", width: 100 },
    { field: "correspond_flight", headerName: "Linked Flight", width: 100 },
    {
      field: "correspond_flight_status",
      headerName: "Linked Flight Status",
      width: 120,
    },
    {
      field: "time_to_crew_rest",
      headerName: "Time to FTL (Hrs)",
      description: "Number of hours from now till FTL",
      width: 120,
      type: "number",
      valueGetter: (params) => {
        const crewRestDatetime = params.row.crewrest_datetime;

        if (!crewRestDatetime || crewRestDatetime === "") {
          return ""; // Handle the case where "Crew Rest Limit DateTime" is not available
        }

        const now = new Date(); // Current date and time

        let crewRestTime = new Date(crewRestDatetime);
        crewRestTime.setHours(crewRestTime.getHours() - 8);

        const timeDifference = (crewRestTime - now) / (1000 * 60 * 60);

        // Format the time difference to display 1 decimal place
        return timeDifference.toFixed(1);
      },
      cellClassName: getCountdownCellClassName,
    },
    {
      field: "actions", // This can be any unique field name
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleMoveButtonClick(params.row.id)}
        >
          Move
        </Button>
      ),
    },
  ];

  return (
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
        "& .high-countdown-cell": {
          color: "#ffffff",
          fontWeight: "600",
        },
        "& .medium-countdown-cell": {
          color: colors.redAccent[200],
          fontWeight: "600",
        },
        "& .low-countdown-cell": {
          color: colors.redAccent[400],
          fontWeight: "600",
        },
        "& .dead-countdown-cell": {
          color: colors.redAccent[600],
          fontWeight: "600",
        },
      }}
    >
      <DataGrid
        checkboxSelection
        rows={data}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(
            newSelection.map((selection) =>
              data.find((row) => row.id === selection)
            )
          );
        }}
      />
      {selectedRows.length > 0 && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleRejectButtonClick}
        >
          Reject
        </Button>
      )}
      <WindowSelectionDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleWindowSelect={handleWindowSelect}
      />
    </Box>
  );
};

export default SuggestedWindowTable;
