import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import instance from "./GlobalAxios";

function ParameterAdjustment() {
  const [parameters, setParameters] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    instance.get(`flights/prioritised`).catch((error) => {
      console.error("Error fetching data:", error);
    });
    instance
      .get(`flights/prioritised/metrics`)
      .then((response) => {
        console.log(response.data);
        for (const key in response.data) {
          if (Object.hasOwnProperty.call(response.data, key)) {
            response.data[key] = response.data[key] * 100;
          }
        }
        setParameters(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  const totalSum =
    parameters.frequencyMetric +
    parameters.durationMetric +
    parameters.odMetric +
    parameters.trfMetric;

  // Define a variable to track if the sum exceeds 100
  const sumNot100 = totalSum !== 100;

  const handleParamChange = (paramName, value) => {
    value = Math.max(0, Math.min(100, value));
    const newParameters = { ...parameters, [paramName]: value };
    setParameters(newParameters);
  };

  const handleUpdatePriorityMetrics = async (isDefault) => {
    try {
      let updatedMetrics;

      if (isDefault) {
        // Reset to default values
        updatedMetrics = {
          frequencyMetric: 0.3,
          durationMetric: 0.2,
          odMetric: 0.17,
          trfMetric: 0.33,
        };
      } else {
        // Use the calculated values based on the sliders
        updatedMetrics = {
          frequencyMetric: parameters.frequencyMetric / totalSum,
          durationMetric: parameters.durationMetric / totalSum,
          odMetric: parameters.odMetric / totalSum,
          trfMetric: parameters.trfMetric / totalSum,
        };
      }
      // Post the updated metrics to the server
      await instance.post(
        "flights/prioritised/updatePriorityMetrics",
        updatedMetrics
      );
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error updating priority metrics:", error);
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography gutterBottom>Flight Frequency</Typography>
          <Slider
            value={parameters.frequencyMetric}
            onChange={(e, value) => handleParamChange("frequencyMetric", value)}
            min={0}
            max={100}
            color="secondary"
          />
          <Typography gutterBottom>
            Value: {parameters.frequencyMetric}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography gutterBottom>Flight Duration</Typography>
          <Slider
            value={parameters.durationMetric}
            onChange={(e, value) => handleParamChange("durationMetric", value)}
            min={0}
            max={100}
            color="secondary"
          />
          <Typography gutterBottom>
            Value: {parameters.durationMetric}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography gutterBottom>OD Pax Load</Typography>
          <Slider
            value={parameters.odMetric}
            onChange={(e, value) => handleParamChange("odMetric", value)}
            min={0}
            max={100}
            color="secondary"
          />
          <Typography gutterBottom>Value: {parameters.odMetric}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography gutterBottom>Trf Pax Load</Typography>
          <Slider
            value={parameters.trfMetric}
            onChange={(e, value) => handleParamChange("trfMetric", value)}
            min={0}
            max={100}
            color="secondary"
          />
          <Typography gutterBottom>Value: {parameters.trfMetric}</Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" marginBottom={2}>
        Total Sum: {totalSum}
        {sumNot100 && (
          <Typography variant="body1" style={{ color: "red" }}>
            Please keep the total sum at 100
          </Typography>
        )}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleUpdatePriorityMetrics(false)}
        disabled={sumNot100}
        style={{ marginRight: "10px" }}
      >
        Update Priority Metrics
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleUpdatePriorityMetrics(true)}
      >
        Reset to Default
      </Button>
    </div>
  );
}

export default ParameterAdjustment;
