import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import instance from "./GlobalAxios";

const StatusOverviewPie = ({ isDashboard = false, numOfHours = 6 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const statusCountArray = [
      {
        status: "Assigned",
        count: 0,
      },
      {
        status: "Unassigned",
        count: 0,
      },
      {
        status: "Cancelled",
        count: 0,
      },
      {
        status: "Departed",
        count: 0,
      },
    ];

    // Make an API request to fetch the data
    instance
      .get(`flights/allDepartureFlightsWithinHours?hours=${numOfHours}`)
      .then((response) => {
        response.data.forEach((flight) => {
          if (flight.estimated_datetime === "") {
            if (flight.flight_status === "Cancelled") {
              statusCountArray[2].count += 1;
            } else {
              statusCountArray[1].count += 1;
            }
          } else {
            if (flight.flight_status === "Departed") {
              statusCountArray[3].count += 1;
            } else {
              statusCountArray[0].count += 1;
            }
          }
        });
        const pieData = statusCountArray.map((item) => ({
          id: item.status,
          label: item.status,
          value: item.count,
        }));
        setData(pieData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [numOfHours]);

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          color: "#000000",
        },
      }}
      colors={["#AFCE4C", "#6B4CCE", "#CE4C6E", "#4CCEAC"]}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 5,
          itemWidth: 85,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 15,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default StatusOverviewPie;
