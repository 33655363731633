import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import ArrivalFlights from "./scenes/arrivals";
import DepartureFlights from "./scenes/departures";
import PrioritisedFlights from "./scenes/priority";
import ScheduledArrDepBar from "./scenes/scheduledArrDepBar";
import ConfirmedArrDepBar from "./scenes/confirmedArrDepBar";
import AvailableSlots from "./scenes/availableSlots";
import DataDashboard from "./scenes/dataDashboard";
import RejectedFlights from "./scenes/rejected";
import BacklogFlights from "./scenes/backlog";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Matrix from "./scenes/matrix";
import { Authenticator } from "@aws-amplify/ui-react";
import AuthenticatorComponents from "./AuthenticatorComponents";
import "./authenticatorTheme.css";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <Authenticator components={AuthenticatorComponents} hideSignUp={true}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar />
            <main className="content">
              <Topbar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/arrivals" element={<ArrivalFlights />} />
                <Route path="/departures" element={<DepartureFlights />} />
                <Route path="/priority" element={<PrioritisedFlights />} />
                <Route path="/rejects" element={<RejectedFlights />} />
                <Route path="/backlog" element={<BacklogFlights />} />
                <Route path="/form" element={<Form />} />
                <Route path="/dataDashboard" element={<DataDashboard />} />
                <Route path="/availableSlots" element={<AvailableSlots />} />
                <Route
                  path="/schedArrDepBar"
                  element={<ScheduledArrDepBar />}
                />
                <Route
                  path="/cfmedArrDepBar"
                  element={<ConfirmedArrDepBar />}
                />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/matrix" element={<Matrix />} />
                <Route path="/geography" element={<Geography />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Authenticator>
  );
}

export default App;
