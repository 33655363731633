import { View, Image, useTheme, Text } from "@aws-amplify/ui-react";

const AuthenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Authenticator logo"
          src="https://www.changiairport.com/content/dam/cacorp/home/header-logo/logo-light.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};

export default AuthenticatorComponents;
