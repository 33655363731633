import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import { useAuthenticator } from "@aws-amplify/ui-react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import instance from "../../components/GlobalAxios";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State to manage the menu anchor element
  const [exerciseTime, setExerciseTime] = useState(null);
  const [singleRunwayTime, setSingleRunwayTime] = useState(null);
  const [exerciseTimeTemp, setExerciseTimeTemp] = useState(null);
  const [singleRunwayTimeTemp, setSingleRunwayTimeTemp] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  useEffect(() => {
    instance
      .get(`flights/exerciseTimings`)
      .then((response) => {
        setExerciseTime(response.data.incidentTime);
        setSingleRunwayTime(response.data.singleRunwayTime);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching prioritised data:", error);
      });
  });

  // If timings not set open this up immediately
  useEffect(() => {
    if ((!exerciseTime || !singleRunwayTime) && loading === false) {
      setDatePickerOpen(true);
    }
  }, [exerciseTime, singleRunwayTime, loading]);

  const handleExerciseTimeChange = (newTime) => {
    console.log(newTime);
    setExerciseTimeTemp(newTime);
  };

  const handleSingleRunwayTimeChange = (newTime) => {
    console.log(newTime);
    setSingleRunwayTimeTemp(newTime);
  };

  const handleFormSubmit = () => {
    const postApiIncident = "flights/updateIncidentTime";
    const postApiRunway = "flights/updateSingleRunwayTime";

    if (exerciseTimeTemp) {
      let exerciseTimeUTC = new Date(exerciseTimeTemp);
      console.log(exerciseTimeUTC);
      exerciseTimeUTC.setHours(exerciseTimeUTC.getHours() - 8);
      console.log(exerciseTimeUTC);
      instance
        .post(postApiIncident, { datetime: exerciseTimeUTC })
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error updating exercise time:", error);
        });
    }

    if (singleRunwayTimeTemp) {
      let singleRunwayTimeUTC = new Date(singleRunwayTimeTemp);
      singleRunwayTimeUTC.setHours(singleRunwayTimeUTC.getHours() - 8);
      instance
        .post(postApiRunway, { datetime: singleRunwayTimeUTC })
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error updating single runway time:", error);
        });
    }

    setExerciseTime(exerciseTimeTemp);
    setSingleRunwayTime(singleRunwayTimeTemp);
    setDatePickerOpen(false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const { signOut } = useAuthenticator((context) => [context.user]);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box display="flex" borderRadius="3px">
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
        <Typography
          variant="h5"
          fontWeight="bold"
          color={colors.greenAccent[500]}
          paddingRight={5}
        >
          Incident Time:{" "}
          <span style={{ color: "white" }}>
            {exerciseTime && dayjs.utc(exerciseTime).format("hh:mm A")}
          </span>
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
          Single Runway Time:{" "}
          <span style={{ color: "white" }}>
            {singleRunwayTime && dayjs.utc(singleRunwayTime).format("hh:mm A")}
          </span>
        </Typography>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={() => setDatePickerOpen(true)}>
          <AccessTimeIcon />
        </IconButton>
        <Dialog
          open={isDatePickerOpen}
          onClose={() => setDatePickerOpen(false)}
        >
          <DialogTitle>Set New Time</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box paddingTop={1}>
                <DateTimePicker
                  label="Incident Time"
                  value={dayjs.utc(exerciseTimeTemp || exerciseTime)}
                  onChange={handleExerciseTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box paddingTop={3} paddingBottom={1}>
                <DateTimePicker
                  label="Single Runway Time"
                  value={dayjs.utc(singleRunwayTimeTemp || singleRunwayTime)}
                  onChange={handleSingleRunwayTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
            <Box display="flex" justifyContent="flex-end" paddingTop={2}>
              <Button onClick={handleFormSubmit} color="secondary">
                Submit
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleMenuOpen}>
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              signOut();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
