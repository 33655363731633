import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import React, { useState, useEffect, Fragment } from "react";
import instance from "./GlobalAxios";
import { line } from "d3-shape";

const PrioritisedFlightsBarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [flightData, setFlightData] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatTick = (tickValue) => {
    return tickValue.substring(8, 13);
  };

  useEffect(() => {
    instance.get(`flights/prioritised`).catch((error) => {
      console.error("Error fetching data:", error);
    });
    instance
      .get("flights/prioritised/hourly")
      .then((response) => {
        setFlightData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const lineColor = "#ffffff";

  const Line = ({ bars, xScale, yScale }) => {
    bars.sort(
      (a, b) => new Date(a.data.data.Hour) - new Date(b.data.data.Hour)
    );

    const lineGenerator = line()
      .x((bar) => xScale(bar.data.data.Hour) + bar.width / 2)
      .y((bar) => {
        // Add a small offset for data points with MAX value of 0
        console.log();
        const offset = bar.data.data.MAX === 0 ? 5 : 0;
        return yScale(bar.data.data.MAX) + offset;
      });

    return (
      <Fragment>
        <path
          d={lineGenerator(bars)}
          fill="none"
          stroke={lineColor}
          strokeWidth={3}
          style={{ pointerEvents: "none" }}
        />
        {bars.map((bar) => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.data.Hour) + bar.width / 2}
            cy={yScale(bar.data.data.MAX)}
            r={3}
            fill="white"
            stroke={lineColor}
            style={{ pointerEvents: "none" }}
          />
        ))}
      </Fragment>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ResponsiveBar
      data={flightData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          color: "#000000",
        },
      }}
      keys={["0 Delay", "1-3 Hrs Delay", "3-6 Hrs Delay", "> 6 Hrs Delay"]}
      indexBy="Hour"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      maxValue={16}
      indexScale={{ type: "band", round: true }}
      colors={[
        colors.redAccent[100],
        colors.redAccent[300],
        colors.redAccent[500],
        colors.redAccent[700],
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickValues: isDashboard
          ? flightData
              .filter((_, index) => index % 5 === 0) // Show every 5th tick
              .map((data) => data["Hour"])
          : flightData
              .filter((_, index) => index % 2 === 0) // Show every 2nd tick
              .map((data) => data["Hour"]),
        tickRotation: 30,
        format: formatTick,
        legend: isDashboard ? undefined : "Actual Window",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Count of Flights",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "theme",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      layers={["grid", "axes", "bars", Line, "markers", "legends"]}
      role="application"
    />
  );
};

export default PrioritisedFlightsBarChart;
