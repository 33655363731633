import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";
import SuggestedWindowTable from "../../components/SuggestedWindowTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Header from "../../components/Header";

const PrioritisedFlights = () => {
  const [data, setData] = useState([]);
  const [concatData, setconcatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  // Fetching the data from our api
  useEffect(() => {
    // Make an API request to fetch the data
    instance
      .get(`flights/prioritised`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    instance
      .get("flights/prioritised/cached")
      .then((response) => {
        setconcatData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function formatDateString(inputDateStr) {
    const inputDate = new Date(inputDateStr);

    const day = inputDate.getUTCDate();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[inputDate.getUTCMonth()];
    const hours = String(inputDate.getUTCHours()).padStart(2, "0");
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, "0");

    return `${day} ${month} ${hours}${minutes}`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      <Header
        title="Suggested Flights"
        subtitle="Suggested OAL Departure Flights By Priority Score"
      />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Flight Tabs"
        variant="scrollable"
        scrollButtons="auto"
        textColor="secondary"
        indicatorColor="secondary"
      >
        {data.map((hourData, index) => (
          <Tab
            key={index}
            label={`${formatDateString(hourData.hour)}`}
            id={`tab-${index}`}
          />
        ))}
        <Tab key={"ALL"} label="ALL" id={`tab-all`} />
      </Tabs>
      {data.map((hourData, index) => (
        <TabPanel key={index} value={value} index={index}>
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="bold"
            sx={{ mt: 2, mb: 1 }}
          >
            Suggested Flights for {formatDateString(hourData.hour)}
          </Typography>
          <SuggestedWindowTable key={hourData.hour} data={hourData.flights} />
        </TabPanel>
      ))}
      {value === data.length && (
        <Box p={3}>
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="bold"
            sx={{ mt: 2, mb: 1 }}
          >
            All Suggested Flights
          </Typography>
          <SuggestedWindowTable data={concatData} />
        </Box>
      )}
    </Box>
  );
};

export default PrioritisedFlights;
