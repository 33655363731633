import { Box } from "@mui/material";
import Header from "../../components/Header";
import ParameterAdjustment from "../../components/MatrixSliders";
import PrioritisedPaxLineChart from "../../components/PrioritisedPaxLineChart";

const Matrix = () => {
  return (
    <Box m="20px">
      <Header title="Priority Matrix" subtitle="Adjust your scoring matrix here" />
      <Box height="75vh">
        <ParameterAdjustment />
        <PrioritisedPaxLineChart/>
      </Box>
    </Box>
  );
};

export default Matrix;