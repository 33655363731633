import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import Header from "../../components/Header";
import ProgressChart from "../../components/ProgressChart";
import ScheduledArrDepBarChart from "../../components/ScheduledArrDepBarChart";
import UpcomingAllocationArrDepBarChart from "../../components/UpcomingAllocationArrDepBarChart";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AffectedPaxBox from "../../components/AffectedPaxBox";
import StatBox from "../../components/StatBox";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";
import { Auth } from "aws-amplify";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hourDiff, setHourDiff] = useState(0);
  const [stats, setStats] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentHour, setCurrentHour] = useState(new Date().getHours());

  // Update the current hour every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentHour(new Date().getHours());
    }, 60000); // Update every minute (60,000 milliseconds)
    return () => {
      clearInterval(intervalId); // Cleanup the interval on unmount
    };
  }, []);

  // Fetching the data from your API
  useEffect(() => {
    instance
      .get(`flights/prioritised`)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching prioritised data:", error);
      });
    instance.get("flights/backlog").catch((error) => {
      console.error("Error fetching backlog:", error);
    });
    instance
      .get(`flights/stats`)
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stats:", error);
      });
    instance
      .get(`flights/incidentTime`)
      .then((response) => {
        const incidentHour = new Date(
          new Date(response.data).setHours(
            new Date(response.data).getHours() - 8
          )
        );
        console.log(incidentHour);
        if (new Date() < incidentHour) {
          setHourDiff(0);
        } else {
          // setting hour as the difference between the current hour and the earliest hour
          setHourDiff(
            Math.max(
              Math.min(
                currentHour - incidentHour.getHours(),
                response.data.length - 1
              ),
              0
            )
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching operation timings:", error);
      });
  }, [currentHour, hourDiff]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="DASHBOARD"
          subtitle={`Welcome, ${Auth.user.attributes.family_name} ${Auth.user.attributes.given_name} `}
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={hourDiff}
            subtitle="Hours Since Incident"
            progress="0.50"
            icon={
              <HourglassBottomIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}
        <Box
          gridColumn="span 4"
          // backgroundColor={colors.blueAccent[600]}
          border={4}
          // borderTop={0}
          // borderBottom={0}
          borderColor={colors.blueAccent[600]}
          display="flex"
          gap="10px"
          padding="5px"
        >
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentDivertedFlights}
              subtitle="Diverted Arrivals"
              progress="0.50"
              icon={
                <SwipeLeftIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentCancelledArrivals}
              subtitle="Cancelled Arrivals"
              progress="0.50"
              icon={
                <CancelScheduleSendIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          border={4}
          borderColor={colors.greenAccent[600]}
          display="flex"
          gap="10px"
          padding="5px"
        >
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentDepartedFlights}
              subtitle="Departed Flights"
              icon={
                <FlightTakeoffIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentCancelledDepartures}
              subtitle="Cancelled Departures"
              icon={
                <AirplanemodeInactiveIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentBackloggedFlights}
              subtitle="Unassigned Departures"
              icon={
                <ContactSupportIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            flex="1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={stats.currentNearFTLFlights}
              subtitle="Near FTL Departures"
              progress="0.50"
              icon={
                <AccessAlarmIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Recovery Progress
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Remaining vs Cleared Passengers
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <ProgressChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          p="0 30px"
          backgroundColor={colors.primary[400]}
        >
          <AffectedPaxBox />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Upcoming Traffic by ETA/ETD
          </Typography>
          <Box height="250px" mt="-20px">
            <UpcomingAllocationArrDepBarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Original Schedule
          </Typography>
          <Box height="250px" mt="-20px">
            <ScheduledArrDepBarChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
