import { Box } from "@mui/material";
import Header from "../../components/Header";
import ScheduledArrDepBarChart from "../../components/ScheduledArrDepBarChart";

const ScheduledArrDepBar = () => {
  return (
    <Box m="20px">
      <Header title="Scheduled Summary" subtitle="Arrival and Departure by Hour" />
      <Box height="75vh">
        <ScheduledArrDepBarChart />
      </Box>
    </Box>
  );
};

export default ScheduledArrDepBar;