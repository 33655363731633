import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";

import Header from "../../components/Header";

const BacklogFlights = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Fetching the data from our api
  useEffect(() => {
    // Make an API request to fetch the data
    instance.get("flights/prioritised").catch((error) => {
      console.error("Error fetching data:", error);
    });
    instance
      .get("flights/backlog")
      .then((response) => {
        // Assuming the API response is an array of flight objects
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Define custom CSS classes for different delay ranges
  const getDelayCellClassName = (params) => {
    const delay = params.value || 0;
    if (delay < 0) {
      return "no-delay-cell";
    } else if (delay <= 3) {
      return "low-delay-cell";
    } else if (delay <= 6) {
      return "medium-delay-cell";
    } else {
      return "high-delay-cell";
    }
  };

  const columns = [
    { field: "flightno", headerName: "Flight NO.", width: 120 },
    { field: "destination", headerName: "Destination", width: 100 },
    { field: "city", headerName: "City", width: 100 },
    { field: "terminal", headerName: "Terminal", width: 100 },
    {
      field: "scheduled_datetime",
      headerName: "Scheduled DateTime",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        if (params.row.scheduled_datetime === "") {
          return "";
        }
        const scheduledDatetime = new Date(params.row.scheduled_datetime);
        const year = scheduledDatetime.getUTCFullYear();
        const month = scheduledDatetime.getUTCMonth() + 1; // Month is 0-based, so add 1
        const day = scheduledDatetime.getUTCDate();
        const hours = scheduledDatetime.getUTCHours();
        const minutes = scheduledDatetime.getUTCMinutes();
        const seconds = scheduledDatetime.getUTCSeconds();

        const formattedDateTime = `${year}-${month
          .toString()
          .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return new Date(formattedDateTime);
      },
      valueFormatter: (params) => {
        console.log(params.value);
        if (params.value == null) {
          return "";
        }
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };

        return params.value.toLocaleString(undefined, options);
      },
    },
    {
      field: "delay_from_now",
      headerName: "Delay (Hrs)",
      width: 120,
      type: "number",
      valueFormatter: (params) => {
        return params.value;
      },
      cellClassName: getDelayCellClassName,
    },
    { field: "total_pax", headerName: "Total Pax", width: 120 },
    { field: "frequency", headerName: "Frequency", width: 120 },
    { field: "duration", headerName: "Duration", width: 120 },
    { field: "priority", headerName: "Priority Score", width: 120 },
    { field: "flight_status", headerName: "Status", width: 120 },
    { field: "correspond_flight", headerName: "Linked Flight", width: 120 },
    {
      field: "correspond_flight_date",
      headerName: "Linked Flight Date",
      width: 150,
    },
    {
      field: "correspond_flight_status",
      headerName: "Linked Flight Status",
      width: 150,
    },
    {
      field: "is_oal",
      headerName: "OAL",
      width: 80,
    },
  ];

  const getRowId = (row) => row.index;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Header
        title="Unassigned Flights"
        subtitle="All Departures that have yet to be given an ETD"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .no-delay-cell": {
            color: "#ffffff",
            fontWeight: "600",
          },
          "& .low-delay-cell": {
            color: colors.redAccent[200],
            fontWeight: "600",
          },
          "& .medium-delay-cell": {
            color: colors.redAccent[400],
            fontWeight: "600",
          },
          "& .high-delay-cell": {
            color: colors.redAccent[600],
            fontWeight: "600",
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map((row, index) => ({ ...row, index }))}
          columns={columns}
          getRowId={getRowId}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BacklogFlights;
