import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Grid, Divider } from "@mui/material";
import { tokens } from "../theme";
import instance from "./GlobalAxios";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
const AffectedPaxBox = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stats, setStats] = useState({
    currentRemainingPassengers: 0,
    currentClearedPassengers: 0,
    currentBackloggedFlights: 0,
    currentDivertedFlights: 0,
    currentNearFTLFlights: 0,
    currentDepartedFlights: 0,
    currentCancelledFlights: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    instance
      .get(`flights/stats`)
      .then((response) => {
        setStats(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching stats", error);
      });
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box mt="25px" mb="17px">
        <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
          Affected Pax As Of Now
        </Typography>
        <Divider sx={{ marginTop: "10px", marginBottom: "30px" }} />
      </Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" color={"secondary"}>
            Stranded Passengers
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <SentimentVeryDissatisfiedIcon
              sx={{ fontSize: "1.5rem", marginRight: "10px" }}
            />
            <Typography variant="h3">
              {stats.currentRemainingPassengers &&
                stats.currentRemainingPassengers}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" color={"secondary"}>
            Cleared Passengers
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <SentimentSatisfiedAltIcon
              sx={{ fontSize: "1.5rem", marginRight: "10px" }}
            />
            <Typography variant="h3">
              {stats.currentClearedPassengers && stats.currentClearedPassengers}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AffectedPaxBox;
