import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import instance from "./GlobalAxios";

const WindowSelectionDialog = ({ open, handleClose, handleWindowSelect }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWindow, setSelectedWindow] = useState(7);

  useEffect(() => {
    instance
      .get(`flights/prioritised`)
      .then((response) => {
        setData(response.data);
        setSelectedWindow(formatDateString(response.data[0]["hour"]));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleSelectChange = (event) => {
    setSelectedWindow(event.target.value);
  };

  const handleSubmit = () => {
    handleWindowSelect(selectedWindow);
    handleClose();
  };

  function formatDateString(inputDateStr) {
    const inputDate = new Date(inputDateStr);

    const day = inputDate.getUTCDate();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[inputDate.getUTCMonth()];
    const hours = String(inputDate.getUTCHours()).padStart(2, "0");
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, "0");

    return `${day} ${month} ${hours}${minutes}`;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select Window</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Which window do you want to move it to?
        </DialogContentText>
        <Select value={selectedWindow} onChange={handleSelectChange} fullWidth>
          {data.map((item, index) => (
            <MenuItem key={index} value={item.hour}>
              {formatDateString(item.hour)}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WindowSelectionDialog;
