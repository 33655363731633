import { Box } from "@mui/material";
import Header from "../../components/Header";
import TerminalOverviewPie from "../../components/TerminalOverviewPie";

const Pie = () => {
  return (
    <Box m="20px">
      <Header
        title="Terminal Overview"
        subtitle="Number of Unassigned Passengers by Terminal"
      />
      <Box height="75vh">
        <TerminalOverviewPie />
      </Box>
    </Box>
  );
};

export default Pie;
