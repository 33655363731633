import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What are the departure and arrival flights shown?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Departure flights shown are all departure flights that does not have
            a flight status of 'DEPARTED'. Arrival flights shown are all arrival
            flights in the 24 hours window.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What are considered backlog flights?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Backlog flights are all departure flights, both OAL and non-OAL,
            that have yet been assigned a departure slot.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does the prioritisation algorithm work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Based on the flight's historical OD/TRF pax load, remaining flight
            frequency to the destination city and the duration of flight, we put
            the four factors into a formula to attain a priority score. Then
            according to this priority score, we try to give it a slot as early
            as possible, granted that it fulfill two other criteria: FTL is not
            up and the corresponding arrival flight has been confirmed.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does the priority formula look like?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our formula uses 4 features: x * flight_od_pax/max_flight_od_pax + y
            * flight_trf_pax/max_flight_trf_pax + w *
            1/destination_remaining_frequency + z *
            flight_duration/max_flight_duration Max flight statistics that are
            used in the duration score, od score and trf score refers to the
            maximum duration, od pax and trf pax of all the flights in the
            affected departures flight set. The default value for x, y, w and z
            is 17, 33, 20 and 30.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How does the priority matrix page work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We allow users to adjust the priority matrix by changing the
            weights, explained in the previous answer, the simulation below will
            show you how the new priority matrix will affect the number of
            transfer and od pax cleared.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
