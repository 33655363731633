import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";

import Header from "../../components/Header";

const RejectedFlights = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRecoverButtonClick = () => {
    selectedRows.forEach((selectedRow) => {
      const requestData = { id: selectedRow };
      instance
        .post("flights/recoverFlight", requestData)
        .then((response) => {
          console.log("POST request successful", response);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error sending POST request", error);
        });
    });
  };

  // Fetching the data from our api
  useEffect(() => {
    instance.get(`flights/prioritised`).catch((error) => {
      console.error("Error fetching data:", error);
    });
    instance
      .get("flights/prioritised/rejected")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const columns = [
    { field: "flightno", headerName: "Flight NO.", width: 120 },
    { field: "destination", headerName: "Origin", width: 100 },
    { field: "terminal", headerName: "Terminal", width: 100 },
    {
      field: "scheduled_datetime",
      headerName: "Scheduled DateTime",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        if (params.row.scheduled_datetime === "") {
          return "";
        }
        const scheduledDatetime = new Date(params.row.scheduled_datetime);
        const year = scheduledDatetime.getUTCFullYear();
        const month = scheduledDatetime.getUTCMonth() + 1; // Month is 0-based, so add 1
        const day = scheduledDatetime.getUTCDate();
        const hours = scheduledDatetime.getUTCHours();
        const minutes = scheduledDatetime.getUTCMinutes();
        const seconds = scheduledDatetime.getUTCSeconds();

        const formattedDateTime = `${year}-${month
          .toString()
          .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return new Date(formattedDateTime);
      },
      valueFormatter: (params) => {
        console.log(params.value);
        if (params.value == null) {
          return "";
        }
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };

        return params.value.toLocaleString(undefined, options);
      },
    },
    { field: "flight_type", headerName: "Flight Type", width: 120 },
    { field: "total_pax", headerName: "Total Pax", width: 120 },
    { field: "frequency", headerName: "Frequency", width: 120 },
    { field: "duration", headerName: "Duration", width: 120 },
    { field: "priority", headerName: "Priority Score", width: 120 },
    { field: "runway_id", headerName: "Runway ID", width: 120 },
    { field: "flight_status", headerName: "Status", width: 120 },
    { field: "correspond_flight", headerName: "Linked Flight", width: 120 },
    {
      field: "correspond_flight_date",
      headerName: "Linked Flight Date",
      width: 150,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Header
        title="Rejected Flights"
        subtitle="All Manually Rejected Flights"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map((row, index) => ({ ...row, index }))}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(
              newSelection.map((selection) =>
                data.find((row) => row.id === selection)
              )
            );
          }}
        />
        {selectedRows.length > 0 && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRecoverButtonClick}
          >
            Recover
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RejectedFlights;
