import { Box } from "@mui/material";
import Header from "../../components/Header";
import ProgressChart from "../../components/ProgressChart";

const Line = () => {
  return (
    <Box m="20px">
      <Header title="Recovery Progress" subtitle="Affected Flights and Passengers" />
      <Box height="75vh">
        <ProgressChart />
      </Box>
    </Box>
  );
};

export default Line;