import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";

import Header from "../../components/Header";
import Snackbar from "@mui/material/Snackbar";

export default function DepartureFlights() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const useApiMutation = () => {
    return React.useCallback(
      (flight) =>
        instance
          .post("flights/updateDepartureFlight", flight)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw new Error(
                `Error while saving flight: ${response.statusText}`
              );
            }
          })
          .catch((error) => {
            throw new Error(`Error while saving flight: ${error.message}`);
          }),
      []
    );
  };

  const mutateRow = useApiMutation();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await instance.get("flights/departureFlights");
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();

    const updateInterval = 60 * 1000; // 1 minute
    const intervalId = setInterval(fetchData, updateInterval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  function addHours(date, hours) {
    console.log("hey3");
    console.log(date);
    date.setHours(date.getHours() + hours);
    return date;
  }

  const processRowUpdate = React.useCallback(
    async (newRow, oldRow) => {
      // Here we need to + 8 hrs to consider UTC time
      if (oldRow.airborne_datetime !== newRow.airborne_datetime) {
        console.log("hey");
        newRow.airborne_datetime = addHours(newRow.airborne_datetime, 8);
      }
      if (oldRow.offblock_datetime !== newRow.offblock_datetime) {
        console.log("hey2");
        newRow.offblock_datetime = addHours(newRow.offblock_datetime, 8);
      }
      if (oldRow.crewrest_datetime !== newRow.crewrest_datetime) {
        newRow.crewrest_datetime = addHours(newRow.crewrest_datetime, 8);
      }
      const response = await mutateRow(newRow);
      // Wait until loading becomes false
      while (loading) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100 milliseconds
      }
      setData(data.map((row) => (row.id === response.id ? response : row)));

      setSnackbar({
        children: "Flight successfully updated",
        severity: "success",
      });
      return response;
    },
    [mutateRow, loading, data]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Header
        title="Departing Flights"
        subtitle="All Yet To Depart Flights in the 24 Hrs Window"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .high-countdown-cell": {
            color: "#ffffff",
            fontWeight: "600",
          },
          "& .medium-countdown-cell": {
            color: colors.redAccent[200],
            fontWeight: "600",
          },
          "& .low-countdown-cell": {
            color: colors.redAccent[400],
            fontWeight: "600",
          },
          "& .dead-countdown-cell": {
            color: colors.redAccent[600],
            fontWeight: "600",
          },
          "& .odd-hour-row": {
            backgroundColor: colors.primary[600],
          },
          "& .even-hour-row": {
            backgroundColor: colors.primary[400],
          },
          colorScheme: "dark",
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map((row) => ({ ...row }))}
          getRowClassName={getRowClassName}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
}

// Define custom CSS classes for different countdown ranges
// TODO: CHANGE TO ONE LEVEL
const getCountdownCellClassName = (params) => {
  const countdown = params.value || 0;
  if (countdown <= 2) {
    return "dead-countdown-cell";
  } else {
    return "high-countdown-cell";
  }
};

// Function to calculate the hour from the scheduled_datetime
const getHourFromScheduledDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hour = date.getUTCHours();
  return hour;
};

// Function to determine the row class name based on the hour
const getRowClassName = (params) => {
  const hour = getHourFromScheduledDateTime(params.row.scheduled_datetime);

  // Define class names for different hours
  const classNames = {
    0: "even-hour-row", // even hr
    1: "odd-hour-row", // odd hr
  };

  // Return the class name based on the hour, or a default class if not found
  return classNames[hour % 2] || "";
};

const columns = [
  { field: "flightno", headerName: "Flight NO.", width: 120 },
  { field: "destination", headerName: "Destination", width: 100 },
  { field: "city", headerName: "City", width: 100 },
  { field: "terminal", headerName: "Terminal", width: 100 },
  {
    field: "scheduled_datetime",
    headerName: "Scheduled DateTime",
    width: 160,
    type: "dateTime",
    valueGetter: (params) => {
      if (params.row.scheduled_datetime === "") {
        return "";
      }
      const scheduledDatetime = new Date(params.row.scheduled_datetime);
      const year = scheduledDatetime.getUTCFullYear();
      const month = scheduledDatetime.getUTCMonth() + 1; // Month is 0-based, so add 1
      const day = scheduledDatetime.getUTCDate();
      const hours = scheduledDatetime.getUTCHours();
      const minutes = scheduledDatetime.getUTCMinutes();
      const seconds = scheduledDatetime.getUTCSeconds();

      const formattedDateTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      return new Date(formattedDateTime);
    },
    valueFormatter: (params) => {
      console.log(params.value);
      if (params.value == null) {
        return "";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return params.value.toLocaleString(undefined, options);
    },
  },
  {
    field: "estimated_datetime",
    headerName: "Estimated DateTime",
    width: 160,
    description:
      "If a flight has an ETD, that means it has been assigned a departure slot",
    type: "dateTime",
    editable: true,
    valueGetter: (params) => {
      if (params.row.estimated_datetime === "") {
        return "";
      }
      const estimatedDatetime = new Date(params.row.estimated_datetime);
      const year = estimatedDatetime.getUTCFullYear();
      const month = estimatedDatetime.getUTCMonth() + 1;
      const day = estimatedDatetime.getUTCDate();
      const hours = estimatedDatetime.getUTCHours();
      const minutes = estimatedDatetime.getUTCMinutes();
      const seconds = estimatedDatetime.getUTCSeconds();
      const formattedDateTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
      return new Date(formattedDateTime);
    },
    valueFormatter: (params) => {
      console.log(params.value);
      if (params.value == null) {
        return "";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return params.value.toLocaleString(undefined, options);
    },
  },
  {
    field: "crewrest_datetime",
    headerName: "FTL DateTime",
    description:
      "If a flight has an FTL datetime, that means we have been alerted to its crew rest timing restrictions",
    width: 160,
    type: "dateTime",
    editable: true,
    valueGetter: (params) => {
      if (
        params.row.crewrest_datetime === "" ||
        !params.row.crewrest_datetime
      ) {
        return "";
      }
      const crewrestDatetime = new Date(params.row.crewrest_datetime);
      const year = crewrestDatetime.getUTCFullYear();
      const month = crewrestDatetime.getUTCMonth() + 1;
      const day = crewrestDatetime.getUTCDate();
      const hours = crewrestDatetime.getUTCHours();
      const minutes = crewrestDatetime.getUTCMinutes();
      const seconds = crewrestDatetime.getUTCSeconds();
      const formattedDateTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
      return new Date(formattedDateTime);
    },
    valueFormatter: (params) => {
      console.log(params.value);
      if (params.value == null) {
        return "";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return params.value.toLocaleString(undefined, options);
    },
  },
  {
    field: "time_to_crew_rest",
    headerName: "Time to FTL (Hrs)",
    description: "Number of hours from now till FTL",
    width: 120,
    type: "number",
    valueGetter: (params) => {
      const crewRestDatetime = params.row.crewrest_datetime;

      if (!crewRestDatetime || crewRestDatetime === "") {
        return ""; // Handle the case where "Crew Rest Limit DateTime" is not available
      }

      const now = new Date(); // Current date and time

      let crewRestTime = new Date(crewRestDatetime);
      crewRestTime.setHours(crewRestTime.getHours() - 8);

      const timeDifference = (crewRestTime - now) / (1000 * 60 * 60);

      // Format the time difference to display 1 decimal place
      return timeDifference.toFixed(1);
    },
    cellClassName: getCountdownCellClassName,
  },
  {
    field: "flight_status",
    headerName: "Status",
    editable: true,
    type: "singleSelect",
    valueOptions: [
      "Boarding",
      "Deleted",
      "Departed",
      "Gate Closed",
      "Gate Closing",
      "Gate Open",
      "Last Call",
      "New Gate",
      "Re-timed",
      "Scheduled",
    ],
    width: 120,
  },
  // { field: "ground_handler_sequence", headerName: "GHA Sequence", width: 120 },
  { field: "correspond_flight", headerName: "Linked Flight", width: 120 },
  {
    field: "correspond_flight_status",
    headerName: "Linked Flight Status",
    width: 150,
  },
  { field: "total_pax", headerName: "Total Pax", width: 120 },
  { field: "trf_pax", headerName: "Trf Pax", width: 120 },
  { field: "od_pax", headerName: "OD Pax", width: 120 },
  { field: "frequency", headerName: "Dest Frequency", width: 120 },
  { field: "duration", headerName: "Duration", width: 120 },
  { field: "priority", headerName: "Priority Score", width: 120 },
  // { field: "runway_id", headerName: "Runway ID", width: 120 },
  { field: "flight_type", headerName: "Flight Type", width: 100 },
  {
    field: "is_oal",
    headerName: "OAL",
    width: 80,
  },
];
