import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import instance from "../../components/GlobalAxios";

import Header from "../../components/Header";
import Snackbar from "@mui/material/Snackbar";

export default function ArrivalFlights() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const useApiMutation = () => {
    return React.useCallback(
      (flight) =>
        instance
          .post("flights/updateArrivalFlight", flight)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw new Error(
                `Error while saving flight: ${response.statusText}`
              );
            }
          })
          .catch((error) => {
            throw new Error(`Error while saving flight: ${error.message}`);
          }),
      []
    );
  };

  const mutateRow = useApiMutation();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await instance.get("flights/arrivalFlights");
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  function addHours(date, hours) {
    date.setHours(date.getHours() + hours);

    return date;
  }

  const processRowUpdate = React.useCallback(
    async (newRow, oldRow) => {
      if (oldRow.actual_datetime !== newRow.actual_datetime) {
        newRow.actual_datetime = addHours(newRow.actual_datetime, 8);
      }
      const response = await mutateRow(newRow);
      // Wait until loading becomes false
      while (loading) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100 milliseconds
      }
      setData(data.map((row) => (row.id === response.id ? response : row)));

      setSnackbar({
        children: "Flight successfully updated",
        severity: "success",
      });
      return response;
    },
    [mutateRow, loading, data]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  return (
    <Box m="20px">
      <Header
        title="Arriving Flights"
        subtitle="All Arrival Flights from H+0 to H+24"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiDateTimePicker-root .MuiInputBase-input": {
            color: "secondary",
          },
          "& .odd-hour-row": {
            backgroundColor: colors.primary[600],
          },
          "& .even-hour-row": {
            backgroundColor: colors.primary[400],
          },

          colorScheme: "dark",
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.map((row) => ({ ...row }))}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          getRowClassName={getRowClassName}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
}

// Function to calculate the hour from the scheduled_datetime
const getHourFromScheduledDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hour = date.getUTCHours();
  return hour;
};

// Function to determine the row class name based on the hour
const getRowClassName = (params) => {
  const hour = getHourFromScheduledDateTime(params.row.scheduled_datetime);

  // Define class names for different hours
  const classNames = {
    0: "even-hour-row", // even hr
    1: "odd-hour-row", // odd hr
  };

  // Return the class name based on the hour, or a default class if not found
  return classNames[hour % 2] || "";
};

const columns = [
  { field: "flightno", headerName: "Flight NO.", width: 120 },
  { field: "origin", headerName: "Origin", width: 100 },
  { field: "terminal", headerName: "Terminal", width: 100 },
  {
    field: "scheduled_datetime",
    headerName: "Scheduled DateTime",
    width: 200,
    type: "dateTime",
    valueGetter: (params) => {
      const scheduledDatetime = new Date(params.row.scheduled_datetime);

      const year = scheduledDatetime.getUTCFullYear();
      const month = scheduledDatetime.getUTCMonth() + 1;
      const day = scheduledDatetime.getUTCDate();
      const hours = scheduledDatetime.getUTCHours();
      const minutes = scheduledDatetime.getUTCMinutes();

      const formattedDateTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

      return new Date(formattedDateTime);
    },
    valueFormatter: (params) => {
      console.log(params.value);
      if (params.value == null) {
        return "";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return params.value.toLocaleString(undefined, options);
    },
  },
  {
    field: "estimated_datetime",
    headerName: "Estimated DateTime",
    width: 200,
    type: "dateTime",
    editable: true,
    valueGetter: (params) => {
      if (params.row.estimated_datetime === "") {
        return "";
      }
      const actualDatetime = new Date(params.row.estimated_datetime);

      const year = actualDatetime.getUTCFullYear();
      const month = actualDatetime.getUTCMonth() + 1;
      const day = actualDatetime.getUTCDate();
      const hours = actualDatetime.getUTCHours();
      const minutes = actualDatetime.getUTCMinutes();
      const seconds = actualDatetime.getUTCSeconds();

      const formattedDateTime = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      return new Date(formattedDateTime);
    },
  },
  // { field: "runway_id", headerName: "Runway ID", width: 120 },
  {
    field: "flight_status",
    headerName: "Status",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Landed", "Confirmed", "Scheduled", "Diverted", "Cancelled"],
    width: 120,
  },
  // {
  //   field: "ground_handler_sequence",
  //   headerName: "GHA Sequence",
  //   width: 120,
  // },
  { field: "correspond_flight", headerName: "Linked Flight", width: 120 },
  {
    field: "is_oal",
    headerName: "OAL",
    width: 80,
  },
];
