import { Box } from "@mui/material";
import Header from "../../components/Header";
import PrioritisedFlightsBarChart from "../../components/PrioritisedFlightsBarChart";

const ConfirmedArrDepBar = () => {
  return (
    <Box m="20px">
      <Header
        title="Assignment Summary"
        subtitle="Assigned OAL Flights by Hour"
      />
      <Box height="75vh">
        <PrioritisedFlightsBarChart />
      </Box>
    </Box>
  );
};

export default ConfirmedArrDepBar;
